import "./styles/navbar.css";
import logo from "../assets/aftr-blue.webp";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="Navbar">
      <NavLink id="navLogoBox" to="/">
        <img src={logo} alt="AFTR" />
      </NavLink>
      <div className="navLinks">
        <NavLink to="/">
          <h1>Home</h1>
        </NavLink>
        <NavLink to="/blog">
          <h1>Blog</h1>
        </NavLink>
        <NavLink to="/terms">
          <h1>Terms</h1>
        </NavLink>
      </div>
    </div>
  );
}
