import "./styles/footer.css";
import ig from "../assets/Instagram.webp";
import fb from "../assets/Facebook.webp";
import li from "../assets/Linkedin.webp";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-header">Stay in the loop</div>
      <div className="footer-subhead">Follow us on social media</div>
      <div className="footer-social">
        <a href="https://www.linkedin.com/company/aftr-world/about/">
          <img src={li} alt="Linkedin" />
        </a>
        <a href="https://www.instagram.com/aftrworld">
          <img src={ig} alt="Instagram" />
        </a>
        <a href="https://www.facebook.com/AFTRworld">
          <img src={fb} alt="Facebook" />
        </a>
      </div>
      <div className="footer-copyright">Copyright © 2024 AFTR @ Mintosh.</div>
      <Link to="/terms" className="footer-privacy">Terms & Conditions</Link>
    </div>
  );
}
