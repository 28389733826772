import { createContext, useState } from "react";

const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const [authShown, setAuthShown] = useState(false);
  const handleAuth = () => {
    setAuth(true);
  };
  const handleShown = () => {
    setAuthShown(true);
  };
  return (
    <UserContext.Provider
      value={{
        auth,
        authShown,
        setAuth,
        handleAuth,
        handleShown,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
