import rec4Img from "../assets/03.webp";
import "./styles/rec4.css";

export default function Rec4() {
  const handleScroll = () => {
    const joinForm = document.getElementById("join");
    if (joinForm) {
      joinForm.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <div className="rec4">
      <div className="rec4-content">
        <div className="rec4-content-header">
          Personalized messages and shared memories on those special ocassions
        </div>
        <div className="rec4-content-subtext">
          On all those future special occasions of your loved ones, your AFTR
          digital persona can send personalized messages enriched with shared
          memories and experiences, and may be share some secrets too, making
          your presence felt, and creating new memories which transcends the
          boundaries of physical space and time.
          <br /> <br />
          Be there for your loved ones,forever!
          <br />
          <br />
        </div>
        <button className="rec4-join" onClick={handleScroll}>
          Join Waitlist
        </button>
      </div>
      <div className="rec4-img">
        <img src={rec4Img} alt="rec4IMG" />
      </div>
    </div>
  );
}
