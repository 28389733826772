import { useNavigate } from "react-router-dom";
import "./styles/terms.css";

export default function Terms() {
  const navigate = useNavigate();
  return (
    <div className="Terms">
      <h1>Terms of Service</h1>
      <div className="Terms-Content">
        These Terms of Service outline the terms and conditions for using our
        website (AFTR.world, HEVN.io, SVRG.io, JNAT.io, OTHR.life), their
        associated mobile applications and all services that we provide via
        these websites and mobile applications. In these Terms we will sometimes
        refer to ourselves as “AFTR”, “Company” “we”, or “us”. We’ll refer to
        our websites and mobile applications, together with any content, tools,
        features and functionality, and associated services offered on or
        through them, collectively as “Services”. In these Terms, “you” and
        “your” means you as the user of the Services. <h2>User Agreement</h2>
        By using the Services, you agree to these terms and conditions. You must
        be of legal age (18 or above in most countries) to use the Services. You
        shall not use the Services on behalf of an organisation or other legal
        entity. You must provide accurate and complete information about
        yourself when you register to use the Services. You shall not use the
        Services on behalf of anyone else, irrespective of whether or not you
        are authorised to do so. <h2>Content and Limitations</h2> The Services
        provide the user the ability to: (i) create a digital persona or a
        digital simulation of oneself, to be made available to an identified set
        of connections; (ii) configure for their digital persona to perform
        certain actions, such as share messages, photos, videos, or other media
        with established connections; (iii) chat (via text, audio, video, or
        virtual simulation) with the digital persona or digital simulation of
        other users who have shared their personas; and (iv) follow and chat
        with digital persona or digital simulation of some famous personalities.
        The digital persona of any user is allowed to be available to others for
        conversations only after the real person is deceased. If the digital
        persona of an alive person inadvertently becomes available to others,
        you shall immediately notify us for rectification and further action.
        You agree to immediately notify us of any unauthorised use of the
        Services or any other breach of security or terms of usage. You
        acknowledge the limitations of the technology. The digital personas’
        responses are generated by AI and may not perfectly reflect one's
        personality, experiences, knowledge, or opinions, nor does it create
        true consciousness or contact with the deceased. You are solely
        responsible and liable for the content you provide while using the
        Services. We reserve the right to remove content that is hateful,
        defamatory, illegal, obscene, violent, unlawful, posing security risk,
        against our content guidelines or any discretionary decision of ours.
        While building your digital persona, you agree to share content
        pertaining to yourself, including information, photos, audio, and video.
        You are liable for any misrepresentation or misuse of the Services. You
        are responsible for maintaining the confidentiality of your account and
        for all activities that occur under your account. You are responsible to
        ensure that no sensitive information or content is shared or uploaded
        while using the Services. You acknowledge that we may establish general
        practices and limits concerning use of the Services, including but not
        limited to the maximum period of time that data or user content will be
        retained, and the maximum storage space that will be allotted. You agree
        that we have no responsibility or liability for the deletion or failure
        to store any data or content uploaded to the Services. You further
        acknowledge that we reserve the right to change these general practices
        and limits at any time, at our sole discretion, with or without notice.
        You agree not to connect the Services with any external or independent
        activity outside our websites or applications, and you shall not access
        the Services via any automated means such as robots, botnets, or
        scrapers. <h2>Data Privacy and Security</h2> We take user privacy
        seriously. We will not share your information or the content of your
        conversations with third parties other than the AI models, tools and
        services that we use or integrate with, without your consent, or except
        as required by law. We may access, review, screen, edit, modify and
        delete your content at any time and for any reason, including to provide
        and further develop the Services, or if we think the content violates
        these Terms or any applicable laws. We implement safeguards to protect
        your data, but we cannot guarantee absolute security.{" "}
        <h2>User Data Policy</h2>
        Our application, AFTR, may access, use, store, or share Google user data
        in accordance with Google API Service: User Data Policy. The following
        outlines how our application handles Google user data: <br /> Access:
        AFTR may access Google user data to provide specific features and
        functionalities as described in our Privacy Policy.
        <br /> Use: Google user data accessed by AFTR will be used solely for
        the purposes outlined in our Privacy Policy, such as creating digital
        personas, configuring actions, and facilitating communication between
        users.
        <br /> Storage: Any Google user data stored by AFTR will be securely
        maintained in accordance with industry standards and as outlined in our
        Privacy Policy.
        <br /> Sharing: AFTR may share Google user data with third parties only
        as necessary to provide and improve our services, or as required by law.{" "}
        <h2> Ownership and Intellectual Property </h2>The technology and the
        underlying data used to provide the Services are our property. You are
        granted a non-exclusive licence to use the Services for personal,
        non-commercial purposes. When you create a digital persona using the
        Services and in accordance with these Terms, you own all rights in that
        digital persona and any content used to create the digital persona. You
        grant us, to the fullest extent permitted under the law, a nonexclusive,
        worldwide, royalty-free, fully paid up, transferable, sublicensable,
        perpetual, irrevocable licence to copy, display, upload, perform,
        distribute, transmit, make available, store, access, modify, exploit,
        commercialise and otherwise use the digital persona and all associated
        content for any purpose in any form, medium or technology now known or
        later developed. When you interact with a digital persona using the
        Services and in accordance with these Terms, you grant us, to the to the
        fullest extent permitted under the law, a nonexclusive, worldwide,
        royalty-free, fully paid up, transferable, sublicensable, perpetual,
        irrevocable licence to copy, display, upload, perform, distribute,
        transmit, make available, store, access, modify, exploit, commercialise
        and otherwise use the content for any purpose in any form, medium or
        technology now known or later developed. You acknowledge and agree that
        the Services may contain content or features protected by copyright,
        patent, trademark, trade secret or other proprietary rights and laws.
        You may not copy, modify, decompile, or reverse engineer the technology
        or data. All logos and trademarks related to the Services (AFTR, HEVN,
        SVRG, JNAT, OTHR) belong to us. In addition, there may be other logos
        and trademarks displayed which belong to their respective owners.{" "}
        <h2>Disclaimers, Warranties, Indemnities, and Liabilities</h2> THE
        SERVICES ARE CURRENTLY BEING OFFERED “AS-IS” FOR TRIAL PURPOSES ONLY, ON
        A BEST EFFORT BASIS. WE MAKE NO WARRANTIES, EXPRESS OR IMPLIED,
        REGARDING THE AVAILABILITY, ACCURACY, COMPLETENESS, OR RELIABILITY OF
        THE SERVICES, OR THE SIMULATIONS IT PROVIDES. WE ARE NOT LIABLE FOR ANY
        LOSS OR DAMAGE ARISING FROM YOUR USE OF THE SERVICES, INCLUDING BUT NOT
        LIMITED TO EMOTIONAL DISTRESS, DATA LOSS, FINANCIAL DAMAGES, PHYSICAL
        INJURY, OR REPUTATIONAL LOSS. YOU AGREE TO RELEASE, INDEMNIFY AND HOLD
        US AND OUR AFFILIATES, OFFICERS, EMPLOYEES, DIRECTORS, AND AGENTS
        HARMLESS FROM ANY AND ALL LOSSES, DAMAGES, AND EXPENSES OR ANY KIND
        ARISING OUT OF OR RELATING TO YOUR USE OF THE SERVICES. BY USING OUR
        SERVICES, YOU AGREE THAT THE ONLY REMEDY YOU HAVE IS TO DISCONTINUE THE
        USE OF OUR SERVICES AND TO STOP FURTHER ACCESS, PAYMENTS OR SUBSCRIPTION
        TO THE SERVICES. YOU HEREBY EXPLICITLY WAIVE THE RIGHT TO BRING LEGAL
        PROCEEDINGS AGAINST US, OR THE RIGHT TO A TRIAL BY JURY, OR THE RIGHT TO
        PARTICIPATE IN A CLASS ACTION. IN NO EVENT WILL OUR TOTAL LIABILITY TO
        YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE GREATER OF:
        (I) $10; or (II) THE AMOUNT YOU PAID US DIRECTLY OVER THE LAST 12 MONTHS
        IN CONNECTION WITH YOUR USE OF THE SERVICES. <h2>Termination</h2>
        We may terminate your access to the Service at any time for any reason,
        with or without notice. You may terminate your use of the Service at any
        time. <h2>Governing Law and Disputes</h2>These terms and conditions are
        governed by the laws of India. Any disputes arising from these terms
        will be resolved in the courts of India. <h2>Updates to Terms</h2> We
        may update these terms and conditions from time to time, with or without
        notifying you. You are responsible to periodically check for the updated
        Terms of Service on our website. <h2>Additional Considerations </h2>
        Transparency: We strive to be transparent about how the Services work.
        The AI model is trained on a massive dataset of text and code, but it is
        not sentient and cannot understand the nuances of human conversation. It
        is designed to provide a comforting and familiar space for grieving
        users, but it is not a replacement for human connection or professional
        grief counselling. Emotional Well-being: We understand that using this
        Service may have a significant emotional impact. We encourage you to use
        your best judgement and seek professional help if needed. If you
        experience emotional distress, please reach out to a mental health
        professional. Data Retention: We may retain your data and conversation
        history for an indefinite period of time after termination of your
        Service. You may request to have your data deleted at any time by
        contacting us at support@aftr.world. We commit to removing the digital
        persona and conversations that are directly associated with you, but we
        cannot ensure removal of residual data or derived data.
      </div>
      <div className="Terms-Join">
        <h2>Join our Waitlist</h2>
        <h3>AFTR can create your digital persona forever</h3>
        <div
          onClick={() => {
            navigate("/", {
              state: {
                showCTA: true,
              },
            });
          }}
        >
          <button>Join in</button>
        </div>
      </div>
    </div>
  );
}
