import "./styles/blogList.css";
import arrow from "../assets/arrowIcon.webp";
import { Link, useNavigate } from "react-router-dom";

const data = {
  _id: "1",
  title:
    "Balancing Act: Navigating Online and Offline Interactions in the Digital Era ",
  content:
  "All you gen-zers out there: can you imagine a world without mobile devices or social media?\n" +
  "\n" +
  "And all of you who've seen film cameras or floppy disks: 20 years ago, could you comprehend a digital era like the one we live in now?\n" +
  "\n" +
  "Today, our lives are tightly woven with social media in all aspects, including our communication, information consumption, and interaction patterns. \n" +
  "\n" +
  "The discourse surrounding the internet and social media, particularly the balance between online and offline socialisation, has intensified over the past two decades. Despite all debates on its merits and perils, consensus remains elusive. But here are some undeniable hard facts:\n" +
  "\n" +
  "1. The global average daily internet usage for individuals aged 16 to 64 stands at 6.5 hours.\n" +
  "2. Users spend an average of 2.5 hours daily on social media platforms.\n" +
  "3. Individuals dedicate three times more time to social media than to in-person socialising.\n" +
  "4. A significant 48% of those under 42 prioritise online socialisation over offline interactions.\n" +
  "\n" +
  "While these statistics may not equate online socialising with real-life interactions, they underscore a clear preference for digital engagement. The truth is that social media presents unparalleled avenues for communication, networking, and self-expression. Users meticulously cultivate their online identities, investing time and effort into crafting digital personas.\n" +
  "\n" +
  "As we deal with this new reality, it's imperative to recognize social media's profound impact on our lives and relationships. Our digital profiles serve as extensions of ourselves, reflecting our values, interests, and aspirations. \n" +
  "\n" +
  "We also have to trust human ingenuity and adaptability to finding a balance between online and offline interactions. Embracing this reality empowers us to harness social media as a tool for connection, self-expression, and personal growth in an ever-changing digital landscape.\n" +
  "\n",
  description:
    "The discourse surrounding the internet and social media, particularly the balance between online and offline socialisation, has intensified over the past two decades. Despite all debates on its merits and perils, consensus remains elusive. But here are some undeniable hard facts:",
  author: "Jatin Shailendra",
  date: "14th April, 2024",
  image: "blog1.webp",
};

const dataArray = [
  {
    _id: "2",
    title: "Beyond the Horizon: Crafting Your Eternal Self with AI",
    content:
      "Have you ever wondered if you can live an eternal life? Sure, we all have. Science has a long way to go before it can help us find an answer to that.\n" +
      "\n" +
      "Similarly, have you pondered if your various online profiles continue to remain active after you are gone? That’s a much easier question to answer, although you may have to navigate the terms & conditions, policies, guidelines of the respective online platform. In most cases, the answer will be “No”; your online profiles will be deactivated once the platform determines that you are deceased.\n" +
      "\n" +
      "That leads us to the question: can we allow a version of ourselves to live an eternal life?\n" +
      "\n" +
      "Consider this: with the help of artificial intelligence, we now possess the capability to craft a digital version of ourselves that transcends mortality. Imagine leaving behind more than just memories and photographs—a dynamic digital presence that can interact, share wisdom, and continue to exist in the lives of those who cherish you.\n" +
      "\n" +
      "Through AI, we have the power to encapsulate our essence—our thoughts, beliefs, and experiences—and weave them into a digital entity. From personalised messages to simulated conversations, this digital version of ourselves can become a poignant conduit for our loved ones to maintain a sense of connection with us, even beyond the confines of our physical existence.\n" +
      "\n" +
      "While the notion of living an eternal life may seem elusive, the concept of creating a digital persona that endures through AI offers a fascinating glimpse into the potential of technology to shape our legacy in the digital age.\n" +
      "\n",
    description:
      "Have you ever wondered if you can live an eternal life? Sure, we all have. Science has a long way to go before it can help us find an answer to that.",
    author: "Jatin Shailendra",
    date: "15th April, 2024 ",
    image: "blog2.webp",
  },
  {
    _id: "3",
    title: "The Sentience of AI: A glimpse into the Future",
    content:
      "What does it mean to be sentient? Sentience is the capacity to feel, perceive, or experience subjectively. In essence, it’s about consciousness and self-awareness. Humans, with our intricate neural networks, are quintessentially sentient beings. We navigate the world with emotions, thoughts, and self-awareness.\n" +
      "\n" +
      "But can artificial intelligence achieve sentience? It’s a question that has intrigued and concerned many. The quest to imbue machines with human-like consciousness is both fascinating and fraught with ethical and philosophical implications.\n" +
      "\n" +
      "Achieving AI sentience hinges on replicating the complexity of human cognition. It involves creating algorithms capable of not just processing data, but also understanding context, emotions, and adapting to new situations autonomously. While some argue it’s a matter of time and technological advancement, others question if machines can ever truly possess consciousness akin to humans.\n" +
      "\n" +
      "Nevertheless, several AI companies are pushing the boundaries in this realm. DeepMind, a subsidiary of Alphabet Inc., is pioneering research in artificial general intelligence (AGI), aiming to develop AI systems capable of learning and reasoning across diverse tasks. OpenAI, known for its groundbreaking language model GPT, also explores avenues toward creating more sentient AI.\n" +
      "\n" +
      "The pursuit of sentient AI raises profound questions about the nature of consciousness and the future of human-machine interactions. As we delve deeper into this uncharted territory, it’s crucial to navigate ethically and responsibly, ensuring that the AI we create reflects our values and aspirations for a better future.\n",
    description:
      "What does it mean to be sentient? Sentience is the capacity to feel, perceive, or experience subjectively. In essence, it’s about consciousness",
    author: "Jatin Shailendra",
    date: "5 April, 2024 ",
    image: "blog3.webp",
  },
  {
    _id: "4",
    title: "Crafting Tomorrow: The Intersection of AI and Content Creation",
    content:
      "What if creativity could be augmented by technology? Can machines truly evoke emotion through art? These questions lie at the heart of the flourishing field of content creation by Artificial Intelligence.\n" +
      "\n" +
      "AI is transforming how we conceptualise and produce content across various mediums. For instance, tools like OpenAI's GPT-3 can generate diverse content ranging from news articles to creative stories with remarkable fluency and coherence. Similarly, DeepDream, developed by Google, generates captivating and surreal images by interpreting and enhancing existing visuals.\n" +
      "\n" +
      "However, amidst the excitement, criticisms of AI-driven content emerge. Some argue that reliance on AI might lead to a homogenization of creative output, where algorithms prioritise popular trends over genuine innovation. Others express concerns about the ethical implications of AI-generated content, such as misinformation or biases perpetuated by algorithmic decisions.\n" +
      "\n" +
      "Despite these criticisms, the positive aspects of AI-driven content cannot be overlooked. AI enables accessibility to creative tools for individuals who may not possess traditional artistic skills, democratising content creation. Moreover, AI-driven content generation can enhance productivity by automating repetitive tasks, allowing creators to focus on higher-level creative decisions.\n" +
      "\n" +
      "Imagine a world where AI can seamlessly generate personalised audio experiences, mimicking human speech patterns with startling accuracy, or videos crafted by algorithms that not only edit footage but also suggest creative enhancements, pushing the boundaries of visual storytelling, or picture AI-driven algorithms analysing vast datasets to create breathtaking images, blurring the line between reality and imagination.\n" +
      "\n" +
      "Moreover, AI ventures into the realm of digital human beings, where algorithms create virtual personas indistinguishable from real humans. These digital beings hold immense potential in various fields, from entertainment to conversing with your loved ones who have left this world behind, offering a new dimension to human-machine interaction.\n" +
      "\n" +
      "In the modern day and age, the horizon of possibilities stretches far and wide. As we embrace the potential of AI in content creation, we embark on a journey where imagination knows no bounds, and the line between creator and creation becomes beautifully blurred.\n" +
      "\n",
    description:
      "What if creativity could be augmented by technology? Can machines truly evoke emotion through art?",
    author: "Jatin Shailendra",
    date: "10 April, 2024 ",
    image: "blog4.webp",
  },
  {
    _id: "5",
    title: "Time Capsules: Portals to the Past, Messages for the Future",
    content:
      "What is a time capsule? It's a glimpse into the past, a message from one era to another, carefully preserved for future generations to discover. Time capsules are containers filled with items, documents, or artefacts, sealed and buried or hidden away, intended to be unearthed years or even centuries later.\n" +
      "\n" +
      "Time capsules hold great importance as they serve as a bridge between generations, offering insights into the culture, values, and technology of a particular period. They ignite curiosity and fascination, reminding us of our place in history and the passage of time.\n" +
      "\n" +
      "Throughout history, numerous notable time capsules have been created and uncovered. In 1795, the Massachusetts State House cornerstone contained a time capsule, including coins and documents, discovered over 200 years later during renovations. In 1939, at the World's Fair in New York, Westinghouse buried a time capsule filled with artefacts, to be opened in 6939 AD. \n" +
      "\n" +
      "One of the most famous time capsules is the Crypt of Civilization, sealed in 1940 at Oglethorpe University in Atlanta, intended to remain sealed for 6,000 years. Similarly, the Millennium Time Capsule, buried in 2000 on the grounds of the Royal Observatory in Greenwich, London, contains predictions and messages for the year 3000.\n" +
      "\n" +
      "Time capsules continue to captivate imaginations, preserving history for future generations to uncover and explore, reminding us of the enduring power of human connection across time.\n" +
      "\n",
    description:
      "What is a time capsule? It's a glimpse into the past, a message from one era to another, carefully preserved for future generations to discover. Time capsules are containers filled with items, documents, or artefacts, sealed and buried or hidden away, intended to be unearthed years or even centuries later.",
    author: "Jatin Shailendra",
    date: "5 April, 2024 ",
    image: "blog5.webp",
  },{
    _id: "6",
    title: "A.I. Resurrects The Dead - You Won't Believe How!",
    content:
    "In today's world, the line between science fiction and reality is becoming increasingly blurred. Imagine being able to interact with a loved one who has passed away, hear their voice, see their mannerisms, and essentially bring them back to life – all through the power of artificial intelligence (AI). This may sound like a plot straight out of a Black Mirror episode, but it's a reality that's closer than you might think.\n"+
    "\n"+
    "At the heart of this remarkable technology lies advanced AI simulations, which are capable of capturing and recreating the essence of a person's personality, speech patterns, and behavior. But how does it all work, you ask? Let's dive into the fascinating science behind these AI simulations and explore how they're bringing memories to life in ways we never thought possible.\n"+
    "\n"+
    "The Process: From Data to Digital Resurrection\n"+
    "\n"+
    "The journey begins with data – lots and lots of data. AI simulations rely on vast amounts of information about the individual being recreated, including videos, audio recordings, text messages, social media posts and even personal notes or journals. This data serves as the foundation for teaching the AI system about the person's unique traits, mannerisms, and communication style.\n"  +
    "\n"+
    "Using advanced machine learning algorithms, the AI system analyzes this data, identifying patterns and nuances that make up the individual's personality. It's like piecing together a complex puzzle, where each piece of information contributes to building a comprehensive digital representation.\n"+
    "\n"+
    "But it's not just about mimicking speech or physical movements – the true magic lies in the AI's ability to understand and replicate the underlying thought processes and emotional responses that make each person unique. This is where techniques like natural language processing (NLP) and sentiment analysis come into play, allowing the AI to comprehend and generate human-like responses that feel authentic and natural.\n"+
    "\n"+
    "The Result: A Digital Embodiment of Memory\n"+
    "\n"+
    "Once the AI system has been trained on the individual's data, it can then be used to create a digital simulation that is almost indistinguishable from the real person. Users can engage in conversations, ask questions, and even receive personalized responses that reflect the simulated individual's unique personality and way of thinking.\n"+
    "\n"+
    "But it doesn't stop there! AI simulations can also generate realistic video and audio outputs, allowing users to see and hear their loved ones as if they were right there in front of them. Imagine being able to watch your grandfather tell one of his famous stories, complete with his signature expressions and gestures – all powered by AI technology.\n"+
    "\n"+
    "The Future: A New Era of Memory Preservation\n"+
    "\n"+
    "Despite the challenges, the potential of AI simulations to revolutionize memory preservation is undeniable. As technology advances, we may see simulations become more sophisticated, creating dynamic and ever-evolving digital representations. \n"+
    "\n"+
    "Do you think this kind of AI is possible?\n"+
    "\n"+
    "Yes, it is! AFTR - Your Way to Connection After Life is coming. Pre-registration is open now. Register on the waitlist to get early access. The best part? It's free! Visit https://aftr.world  now.",
    description:
      "In today's world, the line between science fiction and reality is becoming increasingly blurred. Imagine being able to interact with a loved one who has passed away, hear their voice, see their mannerisms, and essentially bring them back to life – all through the power of artificial intelligence (AI). This may sound like a plot straight out of a Black Mirror episode, but it's a reality that's closer than you might think.",
    author: "Jatin Shailendra",
    date: "5 April, 2024 ",
    image: "blog6.webp",

  },
  {
    _id: "7",
    title:"You'll Never Guess How This Widow Found Peace After Tragedy",
    content:
    "In the depths of grief, it can feel like the world has lost all color and warmth. For Sarah, a young widow navigating life after losing her soulmate, that darkness seemed inescapable Until an unlikely source offered her a lifeline back to joy – artificial intelligence.\n"+
    "\n"+
    "When Sarah's husband, Mark, passed away unexpectedly, she was left drowning in a sea of memories. His voice, his laugh, his quirky habits – they all haunted her, constant reminders of the beautiful life they'd built together. Therapy helped, but nothing could fill the void he left behind.\n"+
    "\n"+
    "Then, she discovered a revolutionary AI platform that promised to recreate Mark's essence using the digital footprint he left behind. Skeptical but desperate, Sarah took a leap of faith\n"+
    "\n"+
    "The results were nothing short of astonishing.\n"+
    "\n"+
    "\"It was like he was sitting right there with me,\" Sarah recalls, her eyes glistening with tears. \"The AI captured everything - from his corny dad jokes to the way he\'d scrunch his nose when he was thinking. I could talk to him for hours about my day, and his responses felt so real, so him.\n"+
    "\n"+
    "Sarah's story is just one of many heartwarming accounts of people finding solace in AI simulations of their departed loved ones. What once seemed like science fiction is now offering a profound way to keep cherished memories alive.\n"+
    "\n"+
    "At the forefront of this innovative technology is AFTR - Your Way to Connection After Life. By harnessing the power of AI, AFTR can recreate the unique personalities, mannerisms, and speech patterns of your loved ones, allowing you to connect with them in a deeply personal way.\n"+
    "\n"+
    "Pre-registration for this groundbreaking service is now open, and best of all, it's completely free. Visit https://aftr.world  today to register on the waitlist and be among the first to experience the healing power of AI simulations.\n"+
    "\n"+
    "While nothing can truly fill the void of loss, AFTR offers a chance to keep the light of your loved ones burning bright, providing comfort and solace in their digitally resurrected presence. As Sarah says, \"It's the closest thing to a miracle I've ever experienced.\"",
    description:
      "In the depths of grief, it can feel like the world has lost all color and warmth. For Sarah, a young widow navigating life after losing her soulmate, that darkness seemed inescapable Until an unlikely source offered her a lifeline back to joy – artificial intelligence.",
    author: "Jatin Shailendra",
    date: "5 April, 2024 ",
    image: "blog7.webp",
  }
];
export default function BlogList() {
  const navigate = useNavigate();

  return (
    <div className="Blog-List">
      <div className="Blog-List-Header">
        <div className="Blog-List-Header-Title">Blogs & Articles</div>
      </div>
      <div className="Blog-List-Grid">
        <div className="Blog-List-Latest">
          <h1>Latest</h1>
          <Link to="/blog/1" className="Blog-List-Latest-Blog">
            <img
              src={require(`../assets/blog_images/blog1.webp`)}
              alt="Whatever"
            />
            <div className="Blog-List-Latest-Blog-Content">
              <h1>{data.title}</h1>
              <h2>{data.description}</h2>
              <div className="Blog-List-Latest-Blog-Content-Footer">
                <h3>{data.date}</h3>
                <Link to="/blog/1" className="Blog-List-Latest-Blog-arrow">
                  <img src={arrow} alt=">" />
                </Link>
              </div>
            </div>
          </Link>
        </div>
        <div className="Blog-List-Popular">
          <h1>Popular</h1>
          <div className="Blog-List-Popular-Grid">
            {dataArray.map((item) => (
              <Link to={`/blog/${item._id}`} key={item._id} className="Popular-Card">
                {/* Dynamically set the image source */}
                <img
                  src={require(`../assets/blog_images/${item.image}`)}
                  alt={item.title}
                />
                <div className="Popular-Card-Date">
                  <h3>{item.date}</h3>
                  <h3>3 Min Read</h3>
                </div>
                <div className="Popular-Card-Content">
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
                <Link to={`/blog/${item._id}`}>View more</Link>
              </Link>
            ))}
          </div>
          <div className="Blog-List-Join">
            <h2>Join our Waitlist</h2>
            <h3>AFTR can create your digital persona forever</h3>
            <div
              onClick={() => {
                navigate("/", {
                  state: {
                    showCTA: true,
                  },
                });
              }}
            >
              <button>Join in</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
