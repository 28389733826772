import rec5Img from "../assets/04.webp";
import "./styles/rec5.css";

export default function Rec5() {
  const handleScroll = () => {
    const joinForm = document.getElementById("join");
    if (joinForm) {
      joinForm.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <div className="rec5">
      <div className="rec5-img">
        <img src={rec5Img} alt="rec5IMG" />
      </div>
      <div className="rec5-content">
        <div className="rec5-content-header">
          A new social life in a new virtual world
        </div>
        <div className="rec5-content-subtext">
          Now the best part, imagine your AFTR digital persona residing in a
          metaverse world, a sort of parallel universe, with its own
          inhabitants, living a life in the virtual world on your behalf. We
          will soon be introducing multiple metaverse worlds  to choose from:
          AFTR world (generic, after world), HEVN (spiritual, heaven), SVRG
          (spiritual, svarga), JNAT (spiritual, jannat), OTHR (cyberverse, other
          life)
          <br /> <br />
          Give yourself an eternal digital life in a parallel universe!
          <br />
          <br />
        </div>
        <button className="rec5-join" onClick={handleScroll}>
          Join Waitlist
        </button>
      </div>
    </div>
  );
}
