import "./App.css";
import Footer from "./Components/footer";
import Rec1 from "./Components/rec1";
import Rec2 from "./Components/rec2";
import Rec3 from "./Components/rec3";
import Rec4 from "./Components/rec4";
import Rec5 from "./Components/rec5";

function Home() {
  return (
    <div className="Home">
      <Rec1 />
      <Rec2 />
      <Rec3 />
      <Rec4 />
      <Rec5 />
      <Footer />
    </div>
  );
}

export default Home;
