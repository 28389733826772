import "./App.css";
import { Routes, Route, HashRouter, Navigate } from "react-router-dom";
import Main from "./Components/Main";
import Home from "./Home";
import Terms from "./Components/terms";
import BlogList from "./Components/blogList";
import Blog from "./Components/blog";
import ScrollToTop from "./Components/ScrollToTop";
import { UserProvider } from "./context/userContext";
import OAuth from "./Components/oauth";

function App() {
  return (
    <UserProvider>
      <HashRouter>
        <ScrollToTop />
        <Routes>
          <Route path="" element={<Main />}>
            <Route path="" element={<Home />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/blog" element={<BlogList />} />
            <Route path="/blog/:id" element={<Blog />} />
            <Route path="*" element={<Navigate to="" replace />} />
            <Route path="/oauth-redirect" element={<OAuth />} />
          </Route>
        </Routes>
      </HashRouter>
    </UserProvider>
  );
}

export default App;
