import { useEffect, useState, useContext } from "react";
import "./styles/modal.css";
import { OtpInput } from "reactjs-otp-input";
import check from "../assets/check.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/userContext";

export default function Modal({ isOpen, onClose, contact, type,isVerified,setIsVerified }) {
  const [otp, setOtp] = useState("");
  const { auth, handleAuth } = useContext(UserContext);

  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState([
    window.innerHeight,
    window.innerWidth,
  ]);
  useEffect(() => {
    const windowSizeHandler = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  useEffect(() => {
    if (auth === true) {
      setIsVerified(true);
    } else {
      if (type === "email") {
        try {
          async function getotp() {
            const response = await fetch(
              "https://aftr-waitlist-server-950cefb937d4.herokuapp.com/email/getotp",
              {
                method: "POST",
                body: JSON.stringify({
                  email: contact,
                }),
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (response.ok) {
              console.log("Verification OTP sent");
            }
          }
          if (isOpen && !isVerified) {
            getotp();
          }
        } catch (error) {
          console.log("error: ", error);
        }
      } else {
        try {
          async function getotp() {
            const response = await fetch(
              "https://aftr-waitlist-server-950cefb937d4.herokuapp.com/number/getotp",
              {
                method: "POST",
                body: JSON.stringify({
                  number: contact,
                }),
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (response.ok) {
              console.log("Verification OTP sent");
            }
          }
          if (isOpen && !isVerified) {
            getotp();
          }
        } catch (error) {
          console.log("error: ", error);
        }
      }
    }
  }, [isOpen, isVerified, contact, type, auth]);

  useEffect(() => {
    if (isVerified === true) {
      setTimeout(() => onClose(), 3000);
    }
  }, [isVerified, onClose]);
  if (!isOpen) return null;

  async function verifyOTP(e) {
    if (type === "email") {
      try {
        e.preventDefault();
        const response = await fetch(
          "https://aftr-waitlist-server-950cefb937d4.herokuapp.com/email/verifyotp",
          {
            method: "POST",
            body: JSON.stringify({
              email: contact,
              otp: otp,
            }),
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          console.log("OTP VERIFIED SUCCESSFULLY");
          setIsVerified(true);
          handleAuth();
        }
        else{
          toast.error("Invalid OTP");
        }
      } catch (error) {
        console.log("error: ", error);
      }
    } else {
      try {
        e.preventDefault();
        const response = await fetch(
          "https://aftr-waitlist-server-950cefb937d4.herokuapp.com/number/verifyotp",
          {
            method: "POST",
            body: JSON.stringify({
              number: contact,
              otp: otp,
            }),
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          console.log("OTP VERIFIED SUCCESSFULLY");
          localStorage.setItem("AFTRverified", true);
          setIsVerified(true);
          handleAuth();
        }
        else{
          toast.error("Invalid OTP");
        }
      } catch (error) {
        console.log("error: ", error);

      }
    }
  }

  const handleOTPChange = (otp) => {
    console.log(otp);
    setOtp(otp);
  };
  return (
    // <div className="modal-overlay">
    //   <div className="modal">
    //     <button className="modal-close" onClick={onClose}>
    //       X
    //     </button>
    //     <div className="modal-header">
    //       {status
    //         ? `VERIFIED !!!!`
    //         : `Verification OTP sent to the ${type} ${contact}`}
    //       <button onClick={onClose}>Edit or Change method</button>
    //     </div>
    //     <form onSubmit={verifyOTP} className="modal-form">
    //       <input
    //         type="text"
    //         placeholder="ENTER OTP"
    //         value={otp}
    //         onChange={(e) => setOtp(e.target.value)}
    //       />
    //       <button>VERIFY</button>
    //     </form>
    //   </div>
    // </div>
    <div className="modal-overlay">
      {!isVerified ? (
        <div className="modal">
          <button className="modal-close" onClick={onClose}>
            X
          </button>
          <div className="modal-header">
            <div className="modal-header-text">
              <div className="modal-header-text-top">
                Verification OTP sent to the {type}
              </div>
              <div className="modal-header-text-middle">{contact}</div>
              <div className="modal-header-text-bottom">
                Please enter the OTP sent to your registered {type}
              </div>
            </div>
            <div onClick={onClose} className="modal-edit-text">
              Edit
            </div>
          </div>
          <div className="modal-body">
            <form onSubmit={verifyOTP}>
              <OtpInput
                value={otp}
                onChange={handleOTPChange}
                numInputs={6}
                separator={<span></span>}
                inputStyle={
                  windowSize[0] > 1000
                    ? {
                        width: "3.5rem",
                        height: "3.5rem",
                        fontSize: "2rem",
                        fontWeight: "bold",
                        color: "#4D4D4D",
                        borderRadius: "15px",
                        border: "1px solid #DDDDDD",
                        marginRight: "10px",
                        textAlign: "center",
                      }
                    : {
                        width: "1.5rem",
                        height: "1.5rem",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#4D4D4D",
                        borderRadius: "7px",
                        border: "1px solid #DDDDDD",
                        marginRight: "5px",
                        textAlign: "center",
                      }
                }
                shouldAutoFocus={true}
                isInputNum={true}
              />

              <div className="modal-body-sub-section">
                {/* <div className="modal-not-got-otp-text">
                Didn't get the OTP?
              </div>
              <div className="modal-resend-otp-text">Resend OTP</div> */}
                <button
                  type="submit"
                  onClick={verifyOTP}
                  className="modal-verify-btn"
                >
                  Verify OTP
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="modal">
          <button className="modal-close" onClick={onClose}>
            X
          </button>
          <div className="modal-success-body">
            <img src={check} alt="check" className="check-img" />

            <div className="modal-success-text-section">
              <div className="modal-success-text-top">Success !</div>
              <div className="modal-success-text-bottom">
                Congratulations! Thank you for joining the waitlist
              </div>
            </div>

            <div className="modal-success-btn-section">
              <button onClick={()=>{
                navigate('/blog');
              }} className="modal-verify-btn">
                Read our blogs
              </button>
              </div>
          </div>
        </div>
      )}
    </div>
  );
}
