import { useEffect, useState, useContext } from "react";
import { isEmail, isMobilePhone } from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import img1 from "../assets/01.webp";
import mintosh from "../assets/mintosh.webp";
import google from "../assets/Google.png";
import facebook from "../assets/Facebook.png";
import "./styles/rec1.css";
import Modal from "./modal";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../context/userContext";
import check from "../assets/check.png";

export default function Rec1() {
  const [contact, setContact] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactType, setContactType] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const { auth, authShown, handleShown } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let type = "";

    if (!contact) {
      toast.error("Please enter a valid email or phone number.");
      return;
    }

    if (isEmail(contact)) {
      type = "email";
    } else if (isMobilePhone(contact)) {
      type = "number";
    } else {
      toast.error("Please enter a valid email or phone number.");
      return;
    }

    setIsModalOpen(true);
    setContactType(type);
  };

  const { state } = useLocation();

  useEffect(() => {
    if (state && state.showCTA) {
      const joinForm = document.getElementById("join");
      if (joinForm) {
        joinForm.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [state]);

  useEffect(() => {
    if (auth === true && authShown === false) {
      handleShown();
      setIsModalOpen(true);
      setIsVerified(true);
    }
  }, [auth, authShown, handleShown]);

  return (
    <div className="rec1">
      <ToastContainer />
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        contact={contact}
        type={contactType}
        isVerified={isVerified}
        setIsVerified={setIsVerified}
      ></Modal>
      <div className="rec1-main">
        <div className="rec1-content">
          <div>
            <div className="rec1-content-header">
              Create your forever digital persona
            </div>
            <div className="rec1-content-subtext">
              Do you want your eternal digital presence and legacy for those who
              cherish you? <br />
              <div className="mobile-break"></div>
            </div>
          </div>
        </div>
        <div className="rec1-img">
          <img src={img1} alt="rec1" />
        </div>
      </div>
      <div className="rec1-section-2">
        {!isVerified ? (
          <div className="rec1-signup-section">
            <div className="rec1-call-to-join-msg">Join in with</div>
            <div className="social-signup" id="join">
              <a
                href="https://aftr-waitlist-server-950cefb937d4.herokuapp.com/google/login"
                className="SocialSignInButton"
              >
                <img src={google} alt="G" />
                Sign up with Google
              </a>
              <a
                href="https://aftr-waitlist-server-950cefb937d4.herokuapp.com/facebook/login"
                className="SocialSignInButton"
              >
                <img src={facebook} alt="F" />
                Sign up with Facebook
              </a>
              {/* <a
              href="https://aftr-waitlist-server-950cefb937d4.herokuapp.com/google/login"
              className="SocialSignInButton"
            >
              <img src={whatsapp} alt="W" />
              Sign up with Whatsapp
            </a> */}
              <h3>OR</h3>
            </div>
            {contactType === "email" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <form className="rec1-join-box" onSubmit={handleSubmit}>
                  <div className="input-div">
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter email address"
                      value={contact}
                      id="emailInput"
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </div>

                  <button type="submit">Join</button>
                </form>
                <div
                  className="join-secondary-option"
                  onClick={() => setContactType("phone")}
                >
                  <div>Join using phone instead</div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <form className="rec1-join-box" onSubmit={handleSubmit}>
                  <div className="input-div">
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={contact}
                      onChange={setContact}
                      defaultCountry="IN"
                    />
                  </div>

                  <button type="submit">Join</button>
                </form>
                <div
                  className="join-secondary-option"
                  onClick={() => setContactType("email")}
                >
                  Join using email instead
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="rec1-verified-section">
             <img src={check} alt="check" className="rec1-check-img" />
             <div className="rec1-verified-text">
              You have already joined the waitlist.
             </div>
          </div>
        )}
        <div className="rec1-stat-section">
          <div className="stat-box">
            <div className="stat-box-users-count">
              <div className="stat-box-user-count-text">Join the</div>
              <div className="stat-box-user-count-number">1000+</div>
            </div>
            <div className="stat-box-text">
              users on the waitlist to enjoy timeless travels and endless
              excitement in the virtual world.
            </div>
          </div>
        </div>
      </div>
      <div className="rec1-mintosh-logo">
        Trusted by
        <Link to="https://mintosh.com">
          <img src={mintosh} alt="Mintosh" />
        </Link>
      </div>
    </div>
  );
}
