import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/userContext";

const OAuthRedirect = () => {
  const { handleAuth } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    handleAuth();
    navigate("/");
  });

  return <div>Redirecting...</div>;
};

export default OAuthRedirect;
