import "./styles/rec2.css";
import icon1 from "../assets/Icon.webp";
import icon2 from "../assets/Icon-2.webp";
import icon3 from "../assets/Icon-1.webp";
export default function Rec2() {
  return (
    <div className="rec2">
      <div className="rec2-header-welcome">WELCOME TO THE AFTR.WORLD</div>
      <div className="rec2-header-what">What we do </div>
      <div className="rec2-box">
        <div className="rec2-card">
          <img src={icon1} alt="icon1" />
          <div className="rec2-card-header">
            Preserving your memories & connections
          </div>
          <div className="rec2-card-subtext">
            AFTR empowers you to shape your eternal digital presence by crafting
            your digital persona in a timeless space where memories persist and
            connections endure
          </div>
        </div>
        <div className="rec2-card">
          <img src={icon2} alt="icon2" />
          <div className="rec2-card-header">
            Connecting the real world & the after world
          </div>
          <div className="rec2-card-subtext">
            AFTR is the portal that connects the physical world and the after
            world,where experiences and wisdom transcend realms and generations
          </div>
        </div>
        <div className="rec2-card">
          <img src={icon3} alt="icon3" />
          <div className="rec2-card-header">
            Perpetuating your digital presence in the after world
          </div>
          <div className="rec2-card-subtext">
            AFTR allows your digital self to reside in the metaverse world of
            your choice and embark on an eternal adventure! AFTR world(generic),
            HEVN(heaven), SVRG(svarga), JNAT(jannat)
          </div>
        </div>
      </div>
    </div>
  );
}
