import rec3Img from "../assets/02.webp";
import "./styles/rec3.css";

export default function Rec3() {
  const handleScroll = () => {
    const joinForm = document.getElementById("join");
    if (joinForm) {
      joinForm.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <div className="rec3">
      <div className="rec3-img">
        <img src={rec3Img} alt="Rec3IMG" />
      </div>
      <div className="rec3-content">
        <div className="rec3-content-header">
          Realtime conversations between your AFTR digital twin and your loved
          ones
        </div>
        <div className="rec3-content-subtext">
          Your AFTR digital persona is like a replica or an extension of
          yourself. Long after you leave this physical world, your digital self
          can keep the bonds alive, fun going, wisdom available for your loved
          ones through meaningful, enriching conversations (chat, audio, video).
          And guess what? You'll leave behind a legacy that's impressive and
          amazing! <br /> <br />
          Enter the new era of emotional connection and reminiscence.
          <br />
          <br />
        </div>
        <button className="rec3-join" onClick={handleScroll}>
          Join Waitlist
        </button>
      </div>
    </div>
  );
}
